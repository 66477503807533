import '../../App.css';

import Header from '../common/Header';
import Footer from '../common/Footer';
import Buyers from './Buyers';
import Suppliers from './Suppliers';
import Hero from './Hero';

function Home() {
  return (
    <div className="main-container">
      <Header/>
      <Hero/>
      <Buyers/>
      <Suppliers/>
      <Footer/>
    </div>
  );
}

export default Home;
