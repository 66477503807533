import React from "react"

function Buyers() {
    return (
      <div id="Buyers">
        <div className="container">
            <section className="features-icons det-ails">
                    <div className="row section-header">
                        <h4>Product Sourcing and Contract Manufacturing for the Food Industry</h4>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                <div className="features-icons-icon d-flex  icon-bra-ails">
                                    <i className="icon-badge m-auto text-primary icon-ails"></i>
                                </div>
                                <h6>Traceable Quality</h6>
                                <p>Reliable, high-quality sourcing with inbuilt traceability</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                                <div className="features-icons-icon d-flex  icon-bra-ails">
                                    <i className="icon-trophy m-auto text-primary icon-ails"></i>
                                </div>
                                <h6>Attractive Prices</h6>
                                <p>Competitive price points with flexible credit facilities</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                                <div className="features-icons-icon d-flex  icon-bra-ails">
                                    <i className="icon-rocket m-auto text-primary icon-ails"></i>
                                </div>
                                <h6>End-to-End Execution</h6>
                                <p>Complete backend execution from sourcing to final delivery</p>
                            </div>
                        </div>
                    </div>
            </section>
        </div>
      </div>
    );
}

export default Buyers;
