import React, { useState } from "react"
import logo from '../../images/logo_top.png';
import { Link } from 'react-router-dom';

function Header() {
    const [isMenuActive, activeMenu] = useState(false)
    return (
        <div className="app-header container">
            <nav className="navbar navbar-expand navbar-light static-top header-a">
                <Link to="/">
                    <div className="logo-company-container">
                        <img src={logo} className="App-logo" alt="logo" />
                        <div className="company-name">Aranyakam</div>
                    </div>
                </Link>
                <div className="nav-container">
                    <div className="collapse navbar-collapse alink" id="navbarSupportedContent">
                        <ul className="nav navbar-nav ml-auto header-link-style">
                            <li className="nav-item">
                                <Link to="/contact-us" className="text-color">Contact Us</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <div className="ham-navbar-container">
                <div className="ham-navbar-header">
                    <Link to="/">
                        <div className="logo-company-container">
                            <img src={logo} className="App-logo-mobile" alt="logo" />
                            <div className="company-name-mobile">Aranyakam</div>
                        </div>
                    </Link>
                    <button
                        type="button"
                        class="collapsed button-nav" data-toggle="collapse"
                        data-target="#bs-example-navbar-collapse-1"
                        aria-expanded="false"
                    >
                      <i class="fa fa-bars"></i>
                    </button>
                </div>
                <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                    <ul class="nav navbar-nav">
                      <li className="ham-nav-item"><Link to="/contact-us" className="text-color">Contact Us</Link></li>
                    </ul>
                </div>
            </div>
            
        </div>
    );
}

export default Header;
