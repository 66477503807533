import React from "react"

function Suppliers() {
    return (
      <div id="Suppliers" className="section-white">
        <div className="container">
            <section className="features-icons det-ails">
                <div className="row section-header">
                    <h4>Empowering Farmer Groups and Enabling Entrepreneurs</h4>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div className="features-icons-icon d-flex  icon-bra-ails">
                                <i className="icon-graph m-auto text-primary icon-ails"></i>
                            </div>
                            <h6>Increasing Farmer Incomes</h6>
                            <p>Profit-sharing with farmers for smoother and reliable incomes</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
                            <div className="features-icons-icon d-flex  icon-bra-ails">
                                <i className="icon-check m-auto text-primary icon-ails"></i>
                            </div>
                            <h6>Financial & Marketing Support</h6>
                            <p>Access to capital and client networks for capacity utilization</p>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="features-icons-item mx-auto mb-0 mb-lg-3">
                            <div className="features-icons-icon d-flex  icon-bra-ails">
                                <i className="icon-wrench m-auto text-primary icon-ails"></i>
                            </div>
                            <h6>SME Upgradation</h6>
                            <p>Upgrading small businesses to serve high-quality requirements</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      </div>
    );
}

export default Suppliers;
