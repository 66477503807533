import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'

import forest from '../../images/forest.jpg'
import food_processing from '../../images/food_processing.jpg'
import woman_winnowing from '../../images/woman_winnowing.jpg'

const slideImages = [
  {'url': forest, 'title': 'Bringing food to the world from villages of India'},
  {'url': food_processing, 'title': 'High Quality Food Sourcing & Processing'},
  {'url': woman_winnowing, 'title': 'Empowering Farmers and Rural Entrepreneurs'}
];

const properties = {
  duration: 2500,
  transitionDuration: 700,
  infinite: true,
  prevArrow: <div style={{paddingLeft: "10px", width: "30px", marginRight: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M351.1 448c-4.797 0-9.688-1.094-14.28-3.375l-320-160C6.844 279.2 0 268.1 0 256c0-12.13 6.844-23.18 17.69-28.62l320-160c15.88-7.875 35.05-1.5 42.94 14.31c7.906 15.81 1.5 35.03-14.31 42.94L103.5 256l262.8 131.4c15.81 7.906 22.22 27.12 14.31 42.94C375 441.5 363.7 448 351.1 448z"/></svg></div>,
  nextArrow: <div style={{paddingRight: "10px", width: "30px", marginLeft: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M32.03 448c-11.75 0-23.05-6.469-28.66-17.69c-7.906-15.81-1.5-35.03 14.31-42.94l262.8-131.4L17.69 124.6C1.875 116.7-4.531 97.51 3.375 81.7c7.891-15.81 27.06-22.19 42.94-14.31l320 160C377.2 232.8 384 243.9 384 256c0 12.12-6.844 23.19-17.69 28.63l-320 160C41.72 446.9 36.83 448 32.03 448z"/></svg></div>
};  

const Hero = () => {
    return (
      <div className="hero-container-style">
        <div className="container">
            <div className="slide-container">
              <Slide {...properties}>
                {slideImages.map((slideImage, index)=> (
                  <div class="image-container">
                    <img style={{ objectFit:"cover" }} src={slideImage.url} className="image-style"/>
                    <h3 class="image-title">{slideImage.title}</h3>
                  </div>
                ))}
              </Slide>
            </div>
          </div>
      </div>
    )
}

export default Hero;