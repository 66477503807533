import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from './components/home/Home';
import ContactUs from './components/ContactUs';
import ScrollToTop from './components/common/ScrollToTop';

const Main = () => {
  return (
    <ScrollToTop>
      <Routes> {/* The Switch decides which component to show based on the current URL.*/}
        <Route path='/' element={<Home/>}></Route>
        <Route path='/contact-us' element={<ContactUs/>}></Route>
      </Routes>
    </ScrollToTop>
  );
}

export default Main;