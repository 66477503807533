import React from "react"
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <div id="Footer" className="footer-style">
            <section className="features-icons text-center det-ails-footer footer">
                <div className="container footer">
                    <div className="row bottom-footer">
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="footer-company-details">
                                    <h5>Aranyakam Ventures</h5> 
                                    <h6>Email: info@aranyakam.co.in</h6>
                                </div>
                           </div>
                        </div>
                        
                        <div className="col-sm-6 footer-links">
                            <div className="alink-footer">
                                <ul className="navbar-nav ml-auto">
                                    <li className="nav-item">
                                        <Link to="/contact-us" className="text-color">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="footer-footer">© Aranyakam Indic Ventures Pvt. Ltd. All Rights Reserved</div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Footer;
