import { React, useState } from "react"
import { GoogleSpreadsheet } from 'google-spreadsheet'

import Header from './common/Header';
import Footer from './common/Footer';
import LinkedinLogo from '../images/LI-Logo.png';

function ContactUs() {
    const [visibility, setVisibility] = useState(false);

    const {
      REACT_APP_PRIVATE_KEY,
      REACT_APP_CLIENT_EMAIL,
      REACT_APP_SPREADSHEET_ID,
      REACT_APP_SHEET_ID
    } = process.env

    const doc = new GoogleSpreadsheet(REACT_APP_SPREADSHEET_ID);

    const appendSpreadsheet = async (row) => {
      try {
        await doc.useServiceAccountAuth({
          client_email: REACT_APP_CLIENT_EMAIL,
          private_key: REACT_APP_PRIVATE_KEY.replace(/\\n/g, '\n'),
        });
        await doc.loadInfo();
        const sheet = doc.sheetsById[REACT_APP_SHEET_ID];
        const result = await sheet.addRow(row);
        //alert('Thanks for reaching out!')
        return result;
      } catch (e) {
        console.error("Error: ", e);
      }
    };

    const handleSubmit = (event) =>{
      const row = {
        Date: new Date(),
        Name: event.target.inputName.value,
        Phone: event.target.inputPhoneNumber.value,
        Email: event.target.inputEmail.value,
        Message: event.target.inputMessage.value,
      };
      event.preventDefault()
      appendSpreadsheet(row);
      setVisibility(true);
      //event.target.style.display = "none";
    };

    return (
      <div id="ContactUs">
      <Header/>
        <div className="contact-us">
          <div className="row">
            <div className="col-sm-2"/>
            <div className="col-sm-4">
              <div className="contactus-section-header">
                <h3>Contact Us</h3>
                <br/>
                <p>
                  Please fill in the details in this form and someone from our team will reach out.
                </p>
                <br/>
                <p>Alternately, you can reach out to us on any of the following channels.</p>
                <div className="contactus-email">
                  <i className="icon-envelope m-auto text-primary icon-email"></i>
                  <a style={{ display: "inline-block", paddingLeft: "20px", color: "green" }} 
                     href="mailto:info@aranyakam.co.in">info@aranyakam.co.in
                  </a>
                </div>
              </div>
              <div className="contactus-linkedin">
                <p style={{ lineHeight: "38px", color: "green" }}>Connect with us on</p>
                <a href="https://www.linkedin.com/company/aranyakam-ventures/" target="_blank">
                  <img className="linkedin-logo" src={LinkedinLogo}/>
                </a>
              </div>
            </div>
            <div className="col-sm-4 contactus-form-submitted" style={{ display: visibility ? "block" : "none"}}>
              <div>Thanks for reaching out! <br/> Our team will get in touch with you shortly.</div>
            </div>
            <div className="col-sm-4 contactus-form" style={{ display: visibility ? "none" : "block" }}>
              <form onSubmit={handleSubmit}>
                <div class="form-group">
                  <label for="inputName">Name</label>
                  <input type="text" class="form-control" id="inputName"
                         placeholder="Enter your name" required/>
                </div>
                <div class="form-group">
                  <label for="inputPhoneNumber">Phone Number</label>
                  <input type="tel" class="form-control" id="inputPhoneNumber" 
                         placeholder="Enter Phone Number" required/>
                </div>
                <div class="form-group">
                  <label for="inputEmail">Email address</label>
                  <input type="email" class="form-control" id="inputEmail"
                         placeholder="Enter email address" required/>
                </div>
                <div class="form-group">
                  <label for="inputMessage">Message</label>
                  <textarea rows="6" class="form-control" id="inputMessage"
                            placeholder="Enter Message"/>
                </div>
                <div>
                  <button type="submit" class="btn btn-primary" style={{ backgroundColor: "green" }}>Submit</button>
                </div>
              </form>
            </div>
            <div className="col-sm-2"/>
          </div>
        </div>
      <Footer/>
     </div>
    );
}

export default ContactUs;
